<template>
  <div>
    <b-modal
      id="ModalAddImg"
      title="ฝากไฟล์รูปภาพ"
      no-close-on-backdrop
      hide-header-close
      hide-footer
      scrollable
      size="lg"
    >
      <div class="col-12 px-3">
        <div class="row">
          <div class="col-12 col-lg-6">
            <div class="row form-inline mb-3">
              <div class="col-5 text-right font-weight-bold">Show On Web :</div>
              <div class="col-7">
                <select class="form-control form-control-sm w-100">
                  <option value="">---กรุณาเลือก Web---</option>
                  <option value="TWD">TWD</option>
                  <option value="BNB">BNB</option>
                  <option value="BNB|TWD">BNB|TWD</option>
                </select>
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-6">
            <div class="row form-inline mb-3">
              <div class="col-5 text-right font-weight-bold">Brand :</div>
              <div class="col-7">
                <input type="text" class="form-control form-control-sm w-100" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- END  -->
      <div class="col-12 px-3">
        <div class="row">
          <div class="col-12 col-lg-6">
            <div class="row form-inline mb-3">
              <div class="col-5 text-right font-weight-bold">Upload by :</div>
              <div class="col-7">
                <select
                  v-model="mode"
                  class="form-control form-control-sm w-100"
                >
                  <option value="">---กรุณาเลือก Type---</option>
                  <option value="catCode">Category Code</option>
                  <option value="sku">SKU</option>
                </select>
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-6" v-show="mode === 'catCode'">
            <div class="row form-inline mb-3">
              <div class="col-5 text-right font-weight-bold">
                Category Code :
              </div>
              <div class="col-7">
                <input
                  type="text"
                  class="form-control form-control-sm w-100"
                  v-model="selectnow"
                  @click="CallModalfindbycatcode()"
                />
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-6" v-show="mode === 'sku'">
            <div class="row form-inline mb-3">
              <div class="col-5 text-right font-weight-bold">SKU :</div>
              <div class="col-7">
                <input type="text" class="form-control form-control-sm w-100" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 col-lg-6">
        <div class="row form-inline mb-3">
          <div class="col-5 text-right font-weight-bold">File image :</div>
          <div class="col-7">
            <input
              type="file"
              class="form-control-file form-control-file-sm"
              accept="image/*"
              ref="file"
              @change="upload($event)"
            />
          </div>
        </div>
      </div>

      <div class="col-12 col-lg-12 order-0 order-lg-12 text-center">
        <img
          v-if="fileb64 !== ''"
          :src="`data:image/jpeg;base64,${fileb64}`"
          alt=""
          class="img-fluid border rounded"
        />
        <div class="col-12 col-lg-12 order-0 order-lg-12 text-center">
          <button
            @click="cancelimg()"
            type="button"
            class="btn mt-2 px-3 btn-sm btn-danger"
            v-if="fileb64 !== ''"
          >
            x
          </button>
        </div>
      </div>
      <div class="col-12 col-lg-12 text-right mb-3 mt-3">
        <button type="button" class="btn bt-main" @click="save()">
          บันทึก
        </button>
        <button
          type="button"
          class="btn btn-default"
          @click="hide(), Clearcatcode(), cancelimg()"
        >
          ยกเลิก
        </button>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "ModalAddImg",
  watch: {
    getcategory: function (v) {
      this.selectnow = v.selectnow;
    },
  },
  computed: {
    getcategory: function () {
      return this.$store.getters["categoryActions/get_category_remark"];
    },
  },
  mounted() {
    this.Clearcatcode();
  },
  data() {
    return {
      ShowOnWeb: "BNB|TWD",
      mode: "",
      fileb64: "",
      selectnow:
        this.$store.getters["categoryActions/get_category_remark"].selectnow,
      reModalfindbycatcode: Date.now() + 150,
    };
  },
  methods: {
    async cancelimg() {
      this.$refs.file.value = "";
      this.fileb64 = "";
      this.file = "";
    },
    Clearcatcode() {
      this.selectnow = "";
      this.$store.dispatch("categoryActions/resetcategoryremark");
    },
    async upload(event) {
      let file = event.target.files[0];
      this.file = file;
      this.fileb64 = "";
      // ------- resize------
      var maxW = 500; //กำหนดความกว้าง
      var maxH = 650; //กำหนดความสูง
      var that = this;
      var canvas = document.createElement("canvas");
      var context = canvas.getContext("2d");
      var img = document.createElement("img");

      var result = "";

      let ff = (img.onload = function () {
        var iw = img.width;
        var ih = img.height;

        var scale = Math.min(maxW / iw, maxH / ih);
        var iwScaled = iw * scale;
        var ihScaled = ih * scale;

        canvas.width = iwScaled;
        canvas.height = ihScaled;

        context.drawImage(img, 0, 0, iwScaled, ihScaled);

        result += canvas.toDataURL("image/jpeg", 1); //1 คือ คุณภาพของรูป ที่ Resize

        // that.imageResize = result; //Base64 รูปภาพ ที่ย่อขนาดแล้ว
        let b64 = String(result);

        var base64Data = b64.replace(/^data:image\/jpeg;base64,/, "");

        that.fileb64 = base64Data;
      });
      img.src = URL.createObjectURL(file);
    },
    CallModalfindbycatcode() {
      this.reModalfindbycatcode = Date.now();
      setTimeout(() => {
        this.$bvModal.show("Modalfindbycatcoderemark");
      }, 100);
    },
    hide() {
      this.$bvModal.hide("ModalAddImg");
    },
  },
};
</script>

<style>
</style>