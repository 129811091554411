<template>
  <div class="container-fluid m-0 p-0">
    <div class="row m-0 p-3">
      <div class="col-12 m-0 p-0 shadow mb-5 bg-white rounded">
        <div class="row m-0 p-3">
          <div class="col-12 col-xl-6 mx-0 mb-3 mb-xl-0">
            <p class="font-1-2s font-600">Link Content</p>
          </div>
        </div>
        <hr class="m-0 p-0" />
        <div class="row m-0 p-4">
          <div class="col-12 col-xl-6">
            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <select class="custom-select" v-model="mode">
                  <option v-for="(e, i) in select_mode" :key="i" :value="e.key">
                    {{ e.name }}
                  </option>
                </select>
              </div>

              <input
                type="search"
                class="form-control"
                placeholder="ค้นหา / Seach"
                v-model="find"
                v-on:keyup.enter="Search()"
              />
              <div class="input-group-append">
                <button class="btn bt-main" @click="Search()">ค้นหา</button>
              </div>
            </div>
          </div>
          <div class="col-12 col-xl-6">
            <div class="row">
              <div class="col-12 col-lg-3 text-right mb-3">
                หมวดหมู่ <span class="text-danger"></span>
              </div>
              <div class="col-12 col-lg-9 mb-3">
                <div class="input-group input-group">
                  <input
                    type="text"
                    class="form-control"
                    v-model="selectnow"
                    @click="CallModalfindbycatcode()"
                  />
                  <div class="input-group-append">
                    <button class="input-group-text" @click="Clearcatcode()">
                      x
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-xl-6">
            <div class="row">
              <div class="col-12 col-lg-3">
                <p style="font-weight: bold">
                  จำนวน&nbsp;
                  <span class="text-main">{{
                    new Intl.NumberFormat().format(totalRows)
                  }}</span>
                  &nbsp;รายการ
                </p>
              </div>
            </div>
          </div>
          <!-- <div class="col-12 col-xl-6">
            <div class="row">
              <div class="col-12 col-lg-12 text-right mb-3">
                <b-button
                  pill
                  variant="primary"
                  title="ฝากไฟล์รูปภาพ"
                  @click="CallModalAddImg()"
                >
                  <b-icon icon="cloud-upload" aria-hidden="true"></b-icon>
                  ฝากไฟล์รูปภาพ
                </b-button>
              </div>
            </div>
          </div> -->
          <div class="col-12">
            <b-table
              :empty-text="'ไม่พบข้อมูล / No Data'"
              :fields="fields"
              :items="items"
              :current-page="page_num"
              :per-page="page_size"
              outlined
              hover
              show-empty
              responsive
              :tbody-tr-class="rowClass"
              class="font-0-8s"
            >
              <template #cell(brand)="row">
                <span class="badge badge-main"> {{ row.item.brand }}</span>
              </template>
              <template #cell(action)="row">
                <span
                  v-html="$svg_icon.view"
                  type="button"
                  class="mr-3 text-main"
                  @click="CallModallinkcontent(row.item)"
                ></span>
              </template>
            </b-table>
          </div>
          <div class="col-12">
            <div class="row justify-content-end">
              <div class="col-6 col-xl-1 input-group-prepend input-group-sm">
                <select class="custom-select" v-model="page_size">
                  <option v-for="(e, i) in page_size_list" :key="i" :value="e">
                    {{ e }}
                  </option>
                </select>
              </div>
              <div class="col-6 col-xl-3">
                <b-pagination
                  v-model="page_num"
                  :total-rows="totalRows"
                  :per-page="page_size"
                  align="fill"
                  size="sm"
                  class="my-0"
                ></b-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Modalfindbycatcode :key="reModalfindbycatcode" />
    <Modallinkcontent
      :key="reModallinkcontent"
      :datainput="dataModallinkcontent"
    />
    <ModalAddImg />
  </div>
</template>

<script>
import Modalfindbycatcode from "@/components/Manage/Remarks/Modalfindbycatcode.vue";
import Modallinkcontent from "@/components/Manage/ManageLinkContent/Modallinkcontent.vue";
import ModalAddImg from "../../components/Manage/ManageLinkContent/ModalAddImg.vue";

export default {
  name: "ManageLinkContent",
  components: { Modalfindbycatcode, Modallinkcontent, ModalAddImg },
  data() {
    return {
      reModalfindbycatcode: Date.now() + 150,
      reModallinkcontent: Date.now() + 250,
      dataModallinkcontent: null,
      selectnow:
        this.$store.getters["categoryActions/get_category_remark"].selectnow,
      catcode:
        this.$store.getters["categoryActions/get_category_remark"].catCode,
      data_user: "",
      find: "",
      page_size: 10,
      page_size_list: [10, 15, 20, 25, 30],
      page_num: 1,
      totalRows: 1,
      mode: "catCode",
      select_mode: [
        { name: "ค้นหา Brand", key: "brand" },
        { name: "ค้นหา catCode", key: "catCode" },
        { name: "ค้นหา ชื่อไฟล์", key: "oldName" },
      ],
      fields: [
        { key: "No", label: "No." },
        { key: "catCode", label: "catCode" },
        { key: "catName", label: "catName", thStyle: { width: "30%" } },
        { key: "brand", label: "Brand", class: "text-center" },
        { key: "oldName", label: "ชื่อไฟล์", thStyle: { width: "20%" } },
        { key: "remark", label: "คำอธิบาย", thStyle: { width: "30%" } },
        { key: "updateDate", label: "Update Date" },

        {
          key: "action",
          label: "",
          class: "text-right",
          thStyle: { width: "8%" },
        },
      ],
      items: [],
      itemlist: [],
    };
  },
  watch: {
    getcategory: function (v) {
      this.selectnow = v.selectnow;
      this.catcode = v.catcode;
      this.getpimLinkContent();
    },
    // page_num: function() {
    //   this.getpimLinkContent();
    // },
    find: function () {
      if (this.find == "") {
        this.unSearch();
      }
    },
  },
  computed: {
    getcategory: function () {
      return this.$store.getters["categoryActions/get_category_remark"];
    },
  },
  mounted() {
    this.getpimLinkContent();
    this.Clearcatcode();
  },
  methods: {
    async remove(data) {
      try {
        let confirm = await this.$serviceMain.showConfirmAlert(
          this,
          `คุณต้องการลบ ${data.oldName}:${data.remark}? / You want to delete ${data.oldName}:${data.remark}?`
        );
        if (confirm) {
          const { skuCode, catName, brand, oldName, remark, link } = data;

          let dataaxios = {
            name: link.split("/")[link.split("/").length - 1],
          };

          var config = {
            method: "delete",
            url: `${this.$cons.API_IMG_UPLOAD}upload`,
            headers: {
              "Content-Type": "application/json",
            },
            data: JSON.stringify(dataaxios),
          };

          // let _remove = await this.$axios(config);

          this.$serviceMain.showSuccessAlert(this, "สำเร็จ / Complete");
        }
      } catch (error) {
        this.$serviceMain.showErrorAlert(
          this,
          error.message == undefined ? error : error.message
        );
      }
    },
    add() {
      //   this.$router.push({ name: "Manageremark", query: { mode: "add" } });
    },
    edit(e) {
      //   this.$router.push({name: "Manageremark", query: { mode: "edit", remarkId: e.remarkId },});
    },
    unSearch() {
      this.items = this.itemlist;
      this.totalRows = this.items.length;
    },
    Search() {
      var results = [];
      if (this.find == "") {
        this.getpimLinkContent();
      } else {
        let find = this.find;
        if (this.mode !== "active") {
          find = this.find;
        } else {
          find =
            String(this.find).toUpperCase() == "YES"
              ? 1
              : String(this.find).toUpperCase() == "NO"
              ? 0
              : this.find;
        }
        var toSearch = String(find)
          .trim()
          .toLocaleUpperCase()
          .replace(/ /g, "");
        var objects = this.itemlist;
        for (var i = 0; i < objects.length; i++) {
          // for (var key in objects[i]) {
          var index = String(objects[i][this.mode])
            .trim()
            .toLocaleUpperCase()
            .replace(/ /g, "");
          if (index.indexOf(toSearch) != -1) {
            results.push(objects[i]);
          }
          // }
        }
        this.items = results;
        this.totalRows = this.items.length;
      }
    },
    async getpimLinkContent() {
      let data = {
        catProduct: "",
        catCode:
          this.catcode == "" ||
          this.catcode == null ||
          this.catcode == undefined
            ? ""
            : this.catcode,
      };
      let getAPI = await this.$serviceAPI.call_API(
        "post",
        `contentFile/getpimlinkcontent`,
        data,
        1
      );
      this.items = getAPI.data;
      this.itemlist = getAPI.data;
      this.totalRows = getAPI.data.length;

      try {
      } catch (error) {
        this.$serviceMain.showErrorAlert(
          this,
          error.message == undefined ? error : error.message
        );
      }
    },
    changepage(e) {
      this.page_size = e.target.value;
      this.page_num = 1;
      this.getuserole();
    },
    rowClass(item, type) {
      if (!item || type !== "row") return;
      if (item.showAll == 1) return "table-main";
    },
    hide() {
      this.$bvModal.hide("Modalfindbycatcoderemark");
    },
    CallModalfindbycatcode() {
      this.reModalfindbycatcode = Date.now();
      setTimeout(() => {
        this.$bvModal.show("Modalfindbycatcoderemark");
      }, 100);
    },
    CallModallinkcontent(data) {
      this.reModallinkcontent = Date.now();
      this.dataModallinkcontent = data;
      setTimeout(() => {
        this.$bvModal.show("Modallinkcontent");
      }, 100);
    },
    Clearcatcode() {
      this.selectnow = "";
      this.$store.dispatch("categoryActions/resetcategoryremark");
    },
    CallModalAddImg() {
      setTimeout(() => {
        this.$bvModal.show("ModalAddImg");
      }, 100);
    },
  },
};
</script>
