<template>
  <b-modal
    id="Modallinkcontent"
    hide-footer
    no-close-on-backdrop
    hide-header-close
    scrollable
    size="lg"
  >
    <template #modal-header="">
      <div class="row w-100">
        <div class="col-10">
          <h5>
            ลิงค์ไฟล์
          </h5>
        </div>
        <div class="col-2 text-right">
          <span type="button" @click="hide()">
            x
          </span>
        </div>
      </div>
    </template>
    <!-- ------------------------------------- end header ------------------- -->
    <!-- ------------- add user --------------------- -->
    <div class="row">
      <!-- {{ datainput }} -->
      <div class="col-12 px-3" v-if="datainput !== null">
        <div class="row align-items-center">
          <div class="col-6  text-center mb-3">
            <img
              v-if="checkimg(datainput.link)"
              :src="$cons.API_IMG_UPLOAD + datainput.link"
              class="img-fluid  shadow  bg-white rounded"
              alt=""
            />
            <embed
              v-else
              :src="$cons.API_IMG_UPLOAD + datainput.link"
              type=""
              class="img-fluid  "
            />

            <!-- <img
              v-else
              src="@/assets/google-docs.png"
              class="img-fluid img"
              alt=""
            /> -->
          </div>
          <div class="col-6  mb-3 ">
            <p class="mt-0 mb-1 p-0">
              <span class="font-600">SkuCode : </span>{{ datainput.skuCode }}
            </p>
            <p class="mt-0 mb-1 p-0">
              <span class="font-600">CatCode/Name : </span
              >{{ datainput.catName }}
            </p>
            <p class="mt-0 mb-1 p-0">
              <span class="font-600">แบรนด์ : </span>{{ datainput.brand }}
            </p>
            <p class="mt-0 mb-1 p-0">
              <span class="font-600">ชื่อไฟล์ : </span>{{ datainput.oldName }}
            </p>
            <p class="mt-0 mb-1 p-0">
              <span class="font-600"> คำอธิบาย : </span>{{ datainput.remark }}
            </p>
            <p class="mt-0 mb-1 p-0">
              <span class="font-600">updateBy : </span>{{ datainput.updateBy }}
            </p>
          </div>
          <div class="col-12">
            <div class="input-group mb-3">
              <input
                type="text"
                class="form-control form-control-sm"
                :value="$cons.API_IMG_UPLOAD + datainput.link"
                disabled
                :id="datainput.link"
              />
              <div class="input-group-append">
                <button
                  type="button"
                  class="btn btn-sm bt-main"
                  @click="copyURL(datainput.link)"
                >
                  <span class="px-1" v-html="$svg_icon.link"></span>Copy
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- ----------------------- -->
    </div>
  </b-modal>
</template>

<script>
export default {
  name: "Modallinkcontent",
  props: ["datainput"],
  data() {
    return {};
  },

  computed: {},
  watch: {},
  mounted() {},
  methods: {
    checkimg(data) {
      let type = data.split(".")[data.split(".").length - 1].toUpperCase();
      if (type == "JPG" || type == "JPEG" || type == "PNG") {
        return true;
      } else {
        return false;
      }
    },

    copyURL(id) {
      /* Get the text field */
      var copyText = document.getElementById(`${id}`);

      /* Select the text field */
      copyText.select();
      copyText.setSelectionRange(0, 99999); /* For mobile devices */

      /* Copy the text inside the text field */
      navigator.clipboard.writeText(copyText.value);

      /* Alert the copied text */
      alert("Copied ");
    },

    hide() {
      this.$bvModal.hide("Modallinkcontent");
    },
  },
};
</script>

<style scoped>
.img {
  width: auto;
  height: 10vh;
}
</style>
